import dayjs from "dayjs";
import Cookies from "js-cookie";
import Pagination from "rc-pagination";
import React, { useEffect, useState } from "react";
import { Button, Card, Form, InputGroup, Modal, Table } from "react-bootstrap";
import { SelectPicker } from "rsuite";
import { API, APIS } from "../../App";
import Layout from '../../layout/Layout';
import { Link } from "react-router-dom";
import moment from "moment";
import DateRangePicker from 'react-bootstrap-daterangepicker'

const pageSize = [10, 25, 50, 100].map(
    item => ({ label: item, value: item })
);
var State_array = [];

const ChallanInformation = () => {
    const token = Cookies.get("fmljwt");
    const [size, setSize] = useState(10);
    const [current, setCurrent] = useState(1);
    const [loading, setIsLoading] = useState(false)
    const [challanDetails, setChallanDetails] = useState()
    const [challanSearch, setChallanSearch] = useState("")
    const [State_Data, setState_Data] = useState([])
    const [selectedState, setSelectedState] = useState("GJ")
    const [state, setState] = useState({
        start: moment().subtract(29, 'days'),
        end: moment(),
    });
    const label = state?.start.format('DD-MM-YYYY') + ' - ' + state?.end.format('DD-MM-YYYY');

    const paginationData = (page, pageSize) => {
        setCurrent(page);
        setSize(pageSize);
    };

    const getChallanDetails = async () => {
        try {
            setIsLoading(true)
            const formData = new FormData()
            formData.append("page", current)
            formData.append("limit", size)
            formData.append("state", selectedState)
            formData.append("startDate", state?.start.format('YYYY-MM-DD'))
            formData.append("endDate", state?.end.format('YYYY-MM-DD'))
            challanSearch && formData.append("searchTerm", challanSearch)
            const results = await APIS.post('/challansearch', formData, { headers: { Authorization: `${token}` } })
            if (results?.data?.status) {
                setChallanDetails(results?.data)
            }else{
                setChallanDetails([])
            }
        } catch (error) {
            console.log('error', error)
        } finally {
            setIsLoading(false)
        }
    }
    useEffect(() => {
        getChallanDetails()
    }, [size, current, state, selectedState])
    useEffect(() => {
        stateGetData()
    }, [])
    const handleSearchChallanDetails = () => {
        getChallanDetails()
        setCurrent(1);
    }
    const handleCallback = (start, end) => {
        setState({ start, end });
    };
    const stateGetData = async () => {
        var state = ['AN', 'AP', 'AR', 'AS', 'BR', 'CH', 'CG', 'DD', 'DL', 'GA', 'GJ', 'HR', 'HP', 'JK', 'JH', 'KA', 'KL', 'LA', 'LD', 'MP', 'MH', 'MN', 'ML', 'MZ', 'NL', 'OD', 'PY', 'PB', 'RJ', 'SK', 'TN', 'TS', 'TR', 'UP', 'UK', 'WB']
        State_array = []
        state?.map((val, index) => {
            State_array.push({ label: val, value: val })
        })
        setState_Data(State_array)
    };

    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3 className="my-1">Challan Information <span>{challanDetails?.totalcount?.challanCount}</span></h3>
                <div className="page-heading-right">
                    <SelectPicker
                        cleanable={false}
                        data={State_Data}
                        searchable={false}
                        defaultValue={"GJ"}
                        className="wv-100 my-1 ms-3"
                        placeholder="Select State"
                    onChange={(e) => { setSelectedState(e); setCurrent(1); }}
                    />
                    <div className="inputgroup  my-1">
                        <Form.Control
                            type="text"
                            name="searchvalue"
                            placeholder="Search by license no..."
                            className="wv-250 ms-3"
                            onChange={(e) => setChallanSearch(e.target.value)}
                        />
                        <i className="bx bx-search" onClick={handleSearchChallanDetails} />
                    </div>
                    <DateRangePicker
                        initialSettings={{
                            startDate: state?.start.toDate(),
                            endDate: state?.end.toDate(),
                            ranges: {
                                Today: [moment().toDate(), moment().toDate()],
                                Yesterday: [
                                    moment().subtract(1, 'days').toDate(),
                                    moment().subtract(1, 'days').toDate(),
                                ],
                                'Last 7 Days': [
                                    moment().subtract(6, 'days').toDate(),
                                    moment().toDate(),
                                ],
                                'Last 30 Days': [
                                    moment().subtract(29, 'days').toDate(),
                                    moment().toDate(),
                                ],
                                'This Month': [
                                    moment().startOf('month').toDate(),
                                    moment().endOf('month').toDate(),
                                ],
                                'Last Month': [
                                    moment().subtract(1, 'month').startOf('month').toDate(),
                                    moment().subtract(1, 'month').endOf('month').toDate(),
                                ],
                            },
                        }}
                        onCallback={handleCallback}
                    >
                        <div
                            id="reportrange"
                            className="form-control wv-225 my-1 ms-3 "
                            style={{
                                cursor: 'pointer',
                            }}
                        >
                            <i className="fa fa-calendar"></i>&nbsp;
                            <span>
                                {label}
                            </span> <i className="fa fa-caret-down"></i>
                        </div>
                    </DateRangePicker>
                    <SelectPicker
                        cleanable={false}
                        onChange={(e) => { setSize(e); setCurrent(1) }}
                        data={pageSize}
                        searchable={false}
                        defaultValue={10}
                        className="wv-100 my-1 ms-3"
                    />

                </div>
            </div>
            <div className="page-content">
                <Card className="radiustop-0">
                    <Card.Body>
                        {loading && <div className="loader table-loader"></div>}
                        <Table className="table-bg-primary" bordered responsive striped>
                            <thead>
                                <tr>
                                    <th width="5%" className="text-center">No</th>
                                    <th width="25%" className="text-center">Registration No.</th>
                                    <th width="25%" className="text-center">Total No. Of Challan</th>
                                    {/* <th width="25%" className="text-center">Source</th> */}
                                    <th width="20%" className="text-center">Show Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                {challanDetails?.data?.length > 0 ? challanDetails?.data?.map((item, index) => {
                                    return (<tr key={index}>
                                        <td className='text-center'>{(current === 1) ? index + 1 : current * size + index + 1 - size}</td>
                                        <td className="text-center">{item?.reg_no}</td>
                                        <td className="text-center">{item?.count}</td>
                                        {/* <td className="text-center">CAR INFO FREE</td> */}
                                        <td className="text-center">
                                            <Link to="/challan-information/view" state={item?.reg_no} className="btn btn-icon btn-transparent btn-danger">
                                                <i className='bx bx-receipt'></i>
                                            </Link>
                                        </td>
                                    </tr>)
                                }) :
                                    <tr>
                                        <td colSpan="100%" className="p-0">
                                            <div className='no-found'>
                                                <img src="../../not-found/image.svg" />
                                                <p>No Found Challan Information</p>
                                            </div>
                                        </td>
                                    </tr>}
                            </tbody>
                        </Table>
                        <div className="pagination-custom">
                            {challanDetails?.totalcount?.rcCount > size && <Pagination
                                showTitle={false}
                                className="pagination-data"
                                onChange={paginationData}
                                total={challanDetails?.totalcount?.rcCount}
                                current={current}
                                pageSize={size}
                                showSizeChanger={false}
                            />}
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </Layout>
    )
}

export default ChallanInformation