import Cookies from "js-cookie";
import Pagination from "rc-pagination";
import React, { useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";
import { SelectPicker } from "rsuite";
import {  APIS } from "../../App";
import Layout from '../../layout/Layout';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from "moment";

var Page_array = [];
const BecomeAgent = () => {
    const token = Cookies.get("fmljwt");
    const [Data, setData] = useState([])
    const [size, setSize] = useState(25);
    const [current, setCurrent] = useState(1);
    const [PageHook, setPageHook] = useState([])
    const [loading, setIsLoading] = useState(true)


    const GetData = async () => {
        setIsLoading(true);
        try {
            const Form = new FormData()
            dateRanges?.start && Form?.append("start_date", start.format('YYYY-MM-DD'))
            dateRanges?.end && Form?.append("end_date", end.format('YYYY-MM-DD'))
            Form.append("page", current)
            Form.append("limit", size)
            const result = await APIS.post("/get-become-agents-data", Form, { headers: { authorization: `${token}` } })
            setData(result?.data)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        PageGetData()
    }, [])

    const paginationData = (page, pageSize) => {
        setCurrent(page);
        setSize(pageSize);
    };


    const PageGetData = async () => {
        var PageNumber = [25, 50, 100]
        Page_array = []
        PageNumber?.map((val, index) => {
            Page_array.push({ label: val, value: val })
        })
        setPageHook(Page_array)
    };
    const handleCallback = (start, end) => {
        setCurrent(1);
        setdateRanges({ start, end });
    };
    const [dateRanges, setdateRanges] = useState({
        start: moment().subtract(0, 'days'),
        end: moment(),
    });
    const { start, end } = dateRanges;
    useEffect(() => {
        GetData()
    }, [size, current, dateRanges])
    const label = start.format('DD-MM-YYYY') + ' - ' + end.format('DD-MM-YYYY');
    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3 className="my-1">Become Agent <span> ({Data.total_count})</span></h3>
                <div className="page-heading-right">

                    <DateRangePicker initialSettings={{
                        startDate: start.toDate(),
                        endDate: end.toDate(),
                        ranges: {
                            Today: [moment().toDate(), moment().toDate()],
                            Yesterday: [
                                moment().subtract(1, 'days').toDate(),
                                moment().subtract(1, 'days').toDate(),
                            ],
                            'Last 7 Days': [
                                moment().subtract(6, 'days').toDate(),
                                moment().toDate(),
                            ],
                            'Last 30 Days': [
                                moment().subtract(29, 'days').toDate(),
                                moment().toDate(),
                            ],
                            'This Month': [
                                moment().startOf('month').toDate(),
                                moment().endOf('month').toDate(),
                            ],
                            'Last Month': [
                                moment().subtract(1, 'month').startOf('month').toDate(),
                                moment().subtract(1, 'month').endOf('month').toDate(),
                            ],
                        },
                    }}
                        onCallback={handleCallback}
                    >
                        {/* <input type="text" name="date" placeholder="Select Date" className="form-control wv-225 my-1 ms-3 " /> */}
                        <div
                            id="reportrange"
                            name="date"
                            className="form-control wv-225 my-1 ms-3 "
                            style={{
                                cursor: 'pointer',
                            }}
                        >
                            <i className="fa fa-calendar"></i>&nbsp;
                            <span>{label}</span> <i className="fa fa-caret-down"></i>
                        </div>
                    </DateRangePicker>

                    <SelectPicker
                        cleanable={false}
                        data={PageHook}
                        searchable={false}
                        value={size}
                        className="wv-75 my-1 ms-3"
                        onChange={(e) => {
                            setSize(Number(e));
                            setCurrent(1);
                        }}
                    />

                </div>
            </div>
            <div className="page-content">
                <Card className="radiustop-0">
                    <Card.Body>
                        {loading && <div className="loader table-loader"></div>}
                        <Table className="table-bg-primary" bordered responsive striped>
                            <thead>
                                <tr>
                                    <th width="5%" className="text-center">No</th>
                                    <th width="20%" className='text-center'>Name</th>
                                    <th width="12%" className='text-center'>Phone Number</th>
                                    <th width="12%" className='text-center'>Car Ins. Sold</th>
                                    <th width="12%" className='text-center'>Bike Ins. Sold</th>
                                    <th width="12%" className='text-center'>Challan Paid</th>
                                    <th width="12%" className='text-center'>Car Sold</th>
                                    <th width="15%" className='text-center'>Date</th>
                                    {/* <th width="8%" className='text-center'>Update Date</th> */}
                                    {/* <th width="10%" className='text-center'>Action</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    Data?.data?.length > 0 ? Data?.data?.map((val, i) => {
                                        return (
                                            <tr key={i}>
                                                <td className='text-center'>{(current === 1) ? i + 1 : current * size + i + 1 - size}</td>
                                                <td className='text-center'>{val.name ? val.name : "-"}</td>
                                                <td className='text-center'>{val.phone_no ? val.phone_no : "-"}</td>
                                                <td className='text-center'>{val.car_insurance_sold ? val.car_insurance_sold : "-"}</td>
                                                <td className='text-center'>{val.bike_insurance_sold ? val.bike_insurance_sold : "-"}</td>
                                                <td className='text-center'>{val?.challan_paid ? val?.challan_paid : "-"}</td>
                                                <td className='text-center'>{val.car_sold ? val.car_sold : "-"}</td>
                                                <td className='text-center'>{val?.created_at ? moment(val?.created_at).format('DD-MM-YYYY') : moment(val?.created_at).format("DD-MM-YYYY")}</td>
                                                {/* <td className='text-center'>{val?.updated_at ? moment(val?.updated_at).format('DD-MM-YYYY') : "-"}</td> */}
                                                {/* <td className='text-center'>{val?.created_at ? moment.utc(val?.created_at).format("DD-MM-YYYY") : "-"}</td>
                                                <td className='text-center'>{val?.updated_at ? moment.utc(val?.updated_at).format('DD-MM-YYYY') : "-"}</td> */}
                                            </tr>
                                        )
                                    }) : <tr>
                                        <td colSpan="100%" className="p-0">
                                            <div className='no-found'>
                                                <img src="../../not-found/image.svg" />
                                                <p>Data Not Found!!</p>
                                            </div>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                        {Data?.total_count > size ? (
                            <div className="pagination-custom">
                                <Pagination
                                    showTitle={false}
                                    className="pagination-data"
                                    onChange={paginationData}
                                    total={Data?.total_count}
                                    current={current}
                                    pageSize={size}
                                    showSizeChanger={false}
                                />
                            </div>
                        ) : (
                            ""
                        )}
                    </Card.Body>
                </Card>
            </div>

        </Layout>
    )
}

export default BecomeAgent